/**
 * The Monthly Payment is calculated using the formula:
 * @param {number} totalCost cost
 * @param {number} rate interest rate
 * @param {number} financePlan 24, 36, 60 months
 * @returns {number} Monthly Payment
 */
export const calculateAPRMonthlyPayment = (totalCost, rate, financePlan) => {
  let monthlyRate = rate / 12;
  let monthlyPayment =
    (totalCost * monthlyRate) / (1 - Math.pow(1 + monthlyRate, -financePlan));
  return monthlyPayment;
};
