import { calcRequiredDownPmt, handleMonthlyPmt } from '.';

/**
 * This function updates the option object when there is no split loan
 * @param {object} active
 * @param {object} option
 * @param {number} minRate
 * @param {number} maxRate
 */

export const handleDownAndMonthlyPmt = (active, option, minRate, maxRate) => {
  // 1. Handle down payment logic
  // ----  If lender allows down payment ----- //
  // --------  When lender requires minimum down payment as a PERCENTAGE (Covered Care for example) ---------- //
  // When the Down Payment input is enabled and amount entered is larger than the original requiredDownPmt
  if (
    active.lender.downpayment > 0 &&
    option.downPmtEnabled &&
    option.downpayment > option.requiredDownPmt
  ) {
    // In this case, we need to split the down payment into 2 parts: (required & additional)
    const { requiredDownPmt, totalFinanced } = calcRequiredDownPmt(
      option.finalTotalCost,
      option.downpayment,
      active.lender.downpayment
    );
    option.requiredDownPmt = requiredDownPmt;
    option.totalFinanced = totalFinanced;

    // if the amount of down payment entered manually is smaller than the required one,
    // Today's Payment defaults to required amount
    option.todaysPayment =
      option.downpayment < option.requiredDownPmt
        ? option.requiredDownPmt
        : option.downpayment;

    // 2. Calculate the monthly payments
    // IMPORTANT: // I'm not sure why we add requiredDownPmt here but it does give the correct monthly payment for Covered Care & UGAT
    option.monthlyPayment = handleMonthlyPmt(
      option.finalTotalCost + option.requiredDownPmt, // <--- Here
      option.todaysPayment,
      minRate,
      maxRate,
      option.financePlan
    );
  }
  // When the Down Payment input is disabled
  else {
    option.requiredDownPmt = active.lender.downpayment * option.finalTotalCost;
    // if the amount of down payment entered manually is smaller than the required one,
    // Today's Payment defaults to required amount
    option.todaysPayment =
      option.downpayment < option.requiredDownPmt
        ? option.requiredDownPmt
        : option.downpayment;

    // 2. Calculate the monthly payments
    option.monthlyPayment = handleMonthlyPmt(
      option.finalTotalCost,
      option.todaysPayment,
      minRate,
      maxRate,
      option.financePlan
    );
  }
};
