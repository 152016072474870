import React, { useContext } from 'react';
import { QuoteContext } from '..';
import { genderNeutral } from '../../utils';
import { SelectLender, STATES } from '../../constants';

function AreasList({ parentArea, bodyHalf }) {
  const { options, setOptions, active, setActive, clearOption } =
    useContext(QuoteContext);
  const isUpgrade = parentArea.name.includes('Upgrade');

  const processUpgradeArea = (area) => {
    // Note: upgrade_from can be more than one area (Lip + Chin as an example)
    // Note: upgrade_from is an array, however upgrade_to is a string
    let upgradeFromAreas = area.upgrade_from.map((areaId) =>
      bodyHalf.find((item) => item.id === areaId)
    ); // upgrade_from is an array
    let upgradeFromPrice = (x) =>
      upgradeFromAreas.reduce((acc, obj) => acc + obj.prices[`cohort_${x}`], 0);
    let upgradeToPrice = (x) =>
      bodyHalf.find((item) => item.id === area.upgrade_to).prices[
        `cohort_${x}`
      ]; // upgrade_to is a string
    area.prices = {
      cohort_1: upgradeToPrice(1) - upgradeFromPrice(1),
      cohort_2: upgradeToPrice(2) - upgradeFromPrice(2),
      cohort_3: upgradeToPrice(3) - upgradeFromPrice(3),
    };
  };

  const handleClick = (area) => {
    if (
      active.lender.name === SelectLender.name ||
      active.state.name === STATES[0].name
    )
      return;
    // Important: Notice here we're only updating the active option.items array
    // Setting the body area to be (selected or unselected) doesn't actually happen here
    const x = active.optionKey;
    // If the area is selected
    if (area.selected && area.selected === true) {
      const activeOption = options[active.optionKey];
      if (activeOption.items.length === 1) {
        clearOption(active.optionKey);
      } else {
        setOptions((prev) => ({
          ...prev,
          [x]: {
            ...options[x],
            items: [...options[x].items.filter((item) => item.id !== area.id)],
          },
        }));
      }
    } else if (
      options[x].items.length > 0 &&
      options[x].items[0].id === 'full_body'
    )
      return; // Don't add more areas if a full body is selected
    else {
      // If the area is NOT selected
      const areaClone = { ...area }; // To avoid altering the original area object
      areaClone.name = genderNeutral(area.name);
      areaClone.parentAreaId = parentArea.id; // Parent Area ID: this is usefull in other components
      // Get area.prices programmatically for upgrade areas
      if (isUpgrade) processUpgradeArea(areaClone);

      const isFullBodyArea = areaClone.id === 'full_body';

      // CSS will default to Upgrade for upgrade areas
      areaClone.discount =
        active.preSale && isUpgrade
          ? 'CSS'
          : //   : (parentArea.id === 'bundles') ? 'BNDLE'
          parentArea.id === 'bundles' &&
            active.discount !== 'Military' &&
            active.discount !== 'Event'
          ? 'BNDLE'
          : active.discount;
      if (isFullBodyArea) {
        // If the active Option has items && it is NOT the last Option -> Move to the next Option
        if (options[x].items.length > 0 && x < Object.keys(options).length) {
          let activeKey = x + 1;
          setOptions((prev) => ({
            ...prev,
            [activeKey]: {
              ...options[activeKey],
              items: [areaClone],
            },
          }));
          setActive((prev) => ({ ...prev, optionKey: activeKey }));
        } else {
          // If the active Option does NOT have items OR it's the last Option, REPLACE current items
          setOptions((prev) => ({
            ...prev,
            [x]: {
              ...options[x],
              items: [areaClone],
            },
          }));
        }
      } else {
        setOptions((prev) => ({
          ...prev,
          [x]: {
            ...options[x],
            items: [...options[x].items, areaClone],
          },
        }));
      }
    }
  };

  return (
    <ul id={parentArea.id} className='row flex-wrap padding-x g-3 my-4'>
      {parentArea.areas.map((area) => (
        <li key={area.id} className='col-auto'>
          <button
            type='button'
            className={`sub-area-item rounded px-3 ${
              active.preSale ? 'primary-bg' : ''
            }  ${area.selected && area.selected === true ? 'selected' : ''}`}
            onClick={() => handleClick(area)}
          >
            {area.name}
          </button>
        </li>
      ))}
    </ul>
  );
}

export default AreasList;
