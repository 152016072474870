import React, { useEffect, useState, useRef } from 'react';
import {
  SelectionsSection,
  AreasContainer,
  OptionsContainer,
  QuoteController,
  QuoteActionsContainer,
  PrintHeader,
  Disclaimer,
} from '.';
import { DEFAULT_ACTIVE, STATES } from '../constants';
import { updateSelections, getDefaultOptionsObj } from '../utils';
import useBodyAreas from '../hooks/useBodyAreas';
import Footer from './Footer';
import useDates from '../hooks/useDates';
import { initialOptionValues } from '../constants';
import { updateOptionProps } from '../utils';

export const QuoteContext = React.createContext();

export function QuoteManager() {
  // 3 options to choose from
  const [options, setOptions] = useState(getDefaultOptionsObj());

  // Keep all the active actions in one state object
  const [active, setActive] = useState(DEFAULT_ACTIVE);

  const PARENTS_AREAS_ARR = useBodyAreas();
  const printRef = useRef(null);

  // Clear ALL Options Button
  const [clearOptionsClicked, setClearOptionsClicked] = useState(false);
  const handleClearOptions = () => {
    setOptions(getDefaultOptionsObj());
    if (localStorage.getItem('stateName') !== null) {
      DEFAULT_ACTIVE.state = STATES.find(
        (state) => state.name === localStorage.getItem('stateName')
      );
    }
    setActive(DEFAULT_ACTIVE);
    setClearOptionsClicked(true);
    setTimeout(() => {
      setClearOptionsClicked(false);
    }, 500);
  };

  // Clear Option
  const clearOption = (key) => {
    setOptions((prev) => ({ ...prev, [key]: initialOptionValues }));
    if (options[1].items.length === 0) {
      // if Option 1 is empty -> make it active
      setActive((prev) => ({ ...prev, optionKey: 1 }));
    } else {
      // else: make the cleared Option active
      setActive((prev) => ({ ...prev, optionKey: +key }));
    }
    // Finally: If the previous Option is empty -> make it active
    if (key > 1 && options[key - 1].items.length === 0) {
      setActive((prev) => ({ ...prev, optionKey: key - 1 }));
    }
  };

  // Updating Selections in the AreasContainer happen here when
  // 1. switching between Options OR
  // 2. an area is added or removed in the ACTIVE Option
  const activeOptionItems = options[active.optionKey].items;
  useEffect(() => {
    // This func does nothing to the options props
    // It only selects or un select body areas on the top of the app
    updateSelections(PARENTS_AREAS_ARR, activeOptionItems);
  }, [active.optionKey, activeOptionItems]);

  // When any prop in the active object changes, All Options must be updated
  useEffect(() => {
    setOptions(() => {
      let optionsClone = { ...options };
      for (let key in optionsClone) {
        optionsClone[key] = updateOptionProps(options[key], active);
      }
      return optionsClone;
    });
  }, [active]);

  // The ACTIVE option props must update when:
  // 1. adding or removing items
  // 2. option down payment changes
  // 3. the finalTotalCost OR the totalFinanced
  const { downpayment, finalTotalCost, totalFinanced } =
    options[active.optionKey];

  useEffect(() => {
    setOptions((prev) => ({
      ...prev,
      [active.optionKey]: updateOptionProps(options[active.optionKey], active),
    }));
  }, [activeOptionItems, downpayment, finalTotalCost, totalFinanced]);

  // Context Values to be passed into nested components
  const contextValue = {
    options,
    setOptions,
    active,
    setActive,
    handleClearOptions,
    clearOption,
  };

  const dates = useDates(options, active);

  return (
    <main className='container-xxl milan-bg-white'>
      <div className='row'>
        <QuoteContext.Provider value={contextValue}>
          {PARENTS_AREAS_ARR.length > 0 && (
            <>
              <QuoteController />
              <AreasContainer
                parentsAreasArr={PARENTS_AREAS_ARR}
                clearOptionsClicked={clearOptionsClicked}
              />
              <div id='print-container' className='px-0' ref={printRef}>
                <PrintHeader {...{ active }} />
                <SelectionsSection />
                <OptionsContainer options={options} />
                <div className='container-fluid print-display'>
                  <Disclaimer dates={dates} textColor='navy' />
                </div>
              </div>
              <QuoteActionsContainer
                {...{ options, printRef, active, dates }}
              />
            </>
          )}
        </QuoteContext.Provider>
      </div>
      <Footer />
    </main>
  );
}

export default QuoteManager;
