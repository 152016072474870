export const DISCOUNTS = {
  '20%': 0.2,
  '30%': 0.3,
  '40%': 0.4,
  '50%': 0.5,
  // '60%': 0.6,
  // 'Event': 0.6,
  BNDLE: 0.6,
  CSS: 0.6,
  Military: 0.6,
  'Prev Tx': 0.7,
};
