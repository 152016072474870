import { DISCOUNTS } from '../constants';

/**
 * Item Final Cost
 * @param {object} item
 * @param {number} itemPrivateSale
 * @param {number} cohort 1, 2, 3
 * @returns {number}
 */
export const itemFinalCost = (item, itemPrivateSale, cohort) => {
  const isBundle = item.parentAreaId === 'bundles';
  const isFullBody = item.id === 'full_body';

  if (isBundle) {
    DISCOUNTS['Military'] = isFullBody ? 0.6 : 0.64;
    DISCOUNTS['BNDLE'] = isFullBody ? 0.5 : 0.6;
  } else {
    DISCOUNTS['Military'] = 0.6;
    DISCOUNTS['BNDLE'] = 0.6;
  }

  // DISCOUNTS['Event'] = isBundle ? 0.64 : 0.6

  let discount = DISCOUNTS[item.discount];

  return +(
    (item.prices[`cohort_${cohort}`] - itemPrivateSale) *
    (1 - discount)
  ).toFixed(2);
};
