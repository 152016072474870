import React, { useContext } from 'react';
import Card from 'react-bootstrap/Card';
import { QuoteContext } from '..';
import OptionOutputsTop from './OptionOutputsTop';
import OptionOutputsBottom from './OptionOutputsBottom';
import AreaRow from './AreaRow';
import DownPaymentInput from './DownPaymentInput';
import DownSplitCheckBoxes from './DownSplitCheckBoxes';
import ClearOptionBtn from './ClearOptionBtn';
import { updateOptionProps } from '../../utils';

export function OptionWrapper({ option, optionKey }) {
  const { options, setOptions, active, setActive, clearOption } =
    useContext(QuoteContext);

  // Do NOT remove this line. Otherwise, some props don't render as expected and some inputs get touchy.
  const optionClone = updateOptionProps(options[optionKey], active);

  const minLoanRequired =
    active.lender.minLoanRequired[optionClone.financePlan];

  const isDisabled =
    optionKey > 1 &&
    option.items.length === 0 &&
    options[optionKey - 1].items.length === 0;
  const onClickOption = (key) => {
    if (isDisabled) return;
    setActive((prev) => ({ ...prev, optionKey: +key }));
  };

  return (
    <div className='col-md-6 col-lg-4 col-print'>
      <div className='h-100'>
        <Card
          className={`option-card card-border milan-bg-light-tur ${
            active.preSale ? 'primary-border' : ''
          } ${isDisabled ? 'disabled' : ''} ${
            +optionKey === +active.optionKey ? 'active' : 'pointer'
          }`}
          onClick={() => onClickOption(optionKey)}
        >
          <div
            className={`output text-center pt-3 ${
              active.preSale ? 'milan-bg-primary-light' : 'milan-bg-light-tur'
            } rounded-top`}
          >
            <h2 className='milan-text-primary d-h text-uppercase mb-0'>
              Option {optionKey}
            </h2>
            {option.items.length > 0 ? (
              <OptionOutputsTop option={optionClone} />
            ) : (
              <Card.Body className='py-1'>
                <h3 className='text-center display-6'>$ _ _ _ _</h3>
                <p className='milan-text-navy text-center'>
                  Per month for <strong>24 months</strong>
                </p>
              </Card.Body>
            )}
          </div>
          <Card.Body
            id='option-items'
            className='p-0 rounded-bottom milan-bg-white'
          >
            {option.items.length > 0 &&
              option.items.map((area, x) => (
                <AreaRow
                  key={x}
                  option={optionClone}
                  {...{ optionKey, area }}
                />
              ))}
          </Card.Body>
          <OptionOutputsBottom option={optionClone} active={active} />
          <Card.Body className='p-0 text-center'>
            {option.finalTotalCost > 0 &&
              minLoanRequired >= 0 &&
              option.totalFinanced > minLoanRequired && (
                <DownSplitCheckBoxes
                  option={optionClone}
                  {...{ active, setOptions, optionKey }}
                />
              )}
            {option.downPmtEnabled && (
              <div
                className='container-fluid pt-1'
                style={{ backgroundColor: '#d0ebeb' }}
              >
                {option.downPmtEnabled &&
                  active.lender.downpayment !== false && (
                    <DownPaymentInput
                      option={optionClone}
                      {...{ active, setOptions }}
                    />
                  )}
                {(!option.downPmtEnabled ||
                  active.lender.downpayment === false) && (
                  <div className='py-2 print-hide' />
                )}
              </div>
            )}
          </Card.Body>
        </Card>
        <ClearOptionBtn option={optionClone} {...{ clearOption, optionKey }} />
      </div>
    </div>
  );
}

export default OptionWrapper;
