import { DISCOUNTS } from '../constants';
import { itemFinalCost } from './itemFinalCost';
import { getActiveFeePercentage } from './getActiveFeePercentage';

/**
 * This function updates each item object in the option.items array
 * @param {object} active
 * @param {object} option
 * @param {number} cohort
 * @param {number} activePrivateSale
 * @returns {void}
 */
export const updateEachItem = (active, option, cohort, activePrivateSale) => {
  const itemPrivateSale = activePrivateSale / option.items.length; // Private Sale portion of each item
  const fee = getActiveFeePercentage(active); // Get the active fee percentage

  option.items.forEach((item) => {
    const price = item.prices[`cohort_${cohort}`];
    item.monthlyPrice = Math.round(price / option.financePlan);
    // --------------- Military Discount For A Bundle Area -----------------//
    const isBundle = item.parentAreaId === 'bundles' && item.id !== 'full_body';
    DISCOUNTS['Military'] = isBundle ? 0.64 : 0.6;
    // DISCOUNTS['Event'] = isBundle ? 0.64 : 0.6
    // ----------------------------------------------------------------//
    item.discountedMonthlyPrice = Math.round(
      (price * (1 - DISCOUNTS[item.discount])) / option.financePlan
    );
    item.finalCost = itemFinalCost(item, itemPrivateSale, cohort);
    item.fee = fee > 0 ? item.finalCost * fee : 0;
  });
};
