import React from 'react';
import CustomCheckbox from './CustomCheckbox';

function DownSplitCheckBoxes({ option, optionKey, setOptions }) {
  const handleCheckBox = () => {
    setOptions((prev) => ({
      ...prev,
      [optionKey]: {
        ...option,
        downPmtEnabled: !option.downPmtEnabled,
      },
    }));
  };

  return (
    <div className='container-fluid milan-bg-secondary print-hide'>
      <div className='row'>
        <div className='col-6'>
          <div className='custom-checkbox py-1 text-start'>
            <CustomCheckbox
              id='downpayment'
              label='Down Payment'
              handleCheckBox={handleCheckBox}
              isChecked={option.downPmtEnabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DownSplitCheckBoxes;
