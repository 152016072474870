import { createLender } from './createLender.ts';

export const SelectLender = createLender(
  'Select Lender',
  { min: 0, max: 0 },
  0,
  {
    24: 0,
    36: 0,
  }
);

export const AlphaeonPrime = createLender(
  'Alphaeon Prime',
  { min: 0, max: 0 },
  0,
  {
    24: 1000,
    36: 3000,
  }
);

export const Concora = createLender('Concora', { min: 0.249, max: 0.249 }, 0, {
  36: 1000,
});

export const CoveredCare = createLender(
  'Covered Care',
  { min: 0.179, max: 0.249 },
  0.05,
  {
    24: 1000,
    36: 3000,
  }
);

export const CoveredCareNoDownPayment = createLender(
  'Covered Care - No Down Payment',
  { min: 0.179, max: 0.249 },
  0,
  {
    24: 1000,
    36: 3000,
  }
);

export const PatientFi = createLender('PatientFi', { min: 0, max: 0 }, 0, {
  24: 1000,
  36: 3000,
});

export const SynchronyPrime = createLender(
  'Synchrony Prime',
  { min: 0, max: 0 },
  0,
  {
    24: 1000,
    36: 3000,
  }
);

export const Synchrony60 = createLender('Synchrony 60', { min: 0, max: 0 }, 0, {
  24: 1000,
  36: 3000,
  60: 4000,
});

export const UGAPrime = createLender('UGA Prime', { min: 0, max: 0 }, 0, {
  24: 1000,
  36: 3000,
});

// UGA Secondary varies by state. But the default rate is 18%
export const UGASecondary = createLender(
  'UGA Secondary',
  { min: 0.18, max: 0.18 },
  0,
  {
    24: 1000,
    36: 3000,
  }
);

// UGAT varies by state. But the default rate is 18%
export const UGAT = createLender('UGAT', { min: 0.18, max: 0.18 }, 0.04, {
  36: 750,
});

export const defaultLenders = [
  AlphaeonPrime,
  CoveredCare,
  CoveredCareNoDownPayment,
  PatientFi,
  SynchronyPrime,
  Synchrony60,
  UGAPrime,
];

// export const AllegroPrime = createLender(
//   'Allegro Prime',
//   { min: 0, max: 0 },
//   0,
//   {
//     24: 1000,
//     36: 1000,
//   }
// );

// export const AllegroSecondary = createLender(
//   'Allegro Secondary (Standard)',
//   { min: 0.2399, max: 0.2399 },
//   0,
//   {
//     36: 1000,
//   }
// );

// export const SynchronyRange = createLender(
//   'Synchrony 4.99 - 7.99',
//   { min: 0.0499, max: 0.0799 },
//   0,
//   {
//     24: 1000,
//     36: 1000,
//   }
// );

// export const Synchrony499 = createLender(
//   'Synchrony 4.99',
//   { min: 0.0499, max: 0.0499 },
//   0,
//   {
//     24: 1000,
//     36: 1000,
//   }
// );
// export const Synchrony799 = createLender(
//   'Synchrony 7.99',
//   { min: 0.0799, max: 0.0799 },
//   0,
//   {
//     24: 1000,
//     36: 1000,
//   }
// );
