import { PRIVATE_SALES, initialOptionValues } from '../constants';
import {
  calculateSum,
  handleDownAndMonthlyPmt,
  updateEachItem,
  isValidSynchrony60,
  getInterestRate,
  processDownPayment,
} from '.';
// import { pifDiscount } from '../constants'; // Deprecated

export const updateOptionProps = (option, active) => {
  if (!option) return; // Sometimes option is being passed as undefined for some reason
  if (option.items.length === 0) return initialOptionValues;

  const cohort = active.state.cohort; // cohort number 1, 2, 3
  const activePrivateSale = PRIVATE_SALES[active.privateSale]; // PS250 or PS500
  option.privateSale = activePrivateSale;
  // option.financePlan = getFinancePlan(active, option); // Deprecated
  option.financePlan = active.financePlan;

  // DISCOUNTS['BNDLE'] = active.preSale ? 0.64 : 0.6

  // -> PIF logic goes here if needed

  updateEachItem(active, option, cohort, activePrivateSale);

  // Note: for option.fee Do NOT do finalTotalCost * fee -> it is not correct
  // Keep this line after updateEachItem() to get the correct fee value
  option.fee = calculateSum(option.items.map((item) => item.fee));

  option.totalOriginalCost = calculateSum(
    option.items.map((item) => item.prices[`cohort_${cohort}`])
  );
  const accurateFinalTotalCost =
    calculateSum(option.items.map((item) => item.finalCost)) + option.fee;
  option.finalTotalCost = Math.round(accurateFinalTotalCost);
  // option.finalTotalCost = accurateFinalTotalCost

  // Total Financed is the final total cost minus Today's Payment
  option.totalFinanced = option.finalTotalCost - option.todaysPayment;
  // ------------- Down Payment Logic ------------------ //
  processDownPayment(active, option);

  // ----- Total Savings ----- //
  option.totalSavings =
    option.totalOriginalCost - option.finalTotalCost + Math.floor(option.fee);

  // --------------- Synchrony 60 Logic --------------- //
  // Keep this logic here after the finalTotalCost is calculated
  const isSynchrony60 = isValidSynchrony60(active, option);
  if (isSynchrony60) option.financePlan = 60;

  const { minRate, maxRate } = getInterestRate(active, isSynchrony60);
  handleDownAndMonthlyPmt(active, option, minRate, maxRate);

  option.additionalDownPmt = option.todaysPayment - option.requiredDownPmt;
  // ******* IMPORTANT ********* // KEEP THIS LOGIC AT THE END // To avoid overwriting the prop somewhere else.
  // $$ The logic below is only to make the sum of the items in the Manager's section match the displayed Your Cost
  // ------------------------------------- ------------------------------------- ------------------------------------- //
  const residue = option.finalTotalCost - accurateFinalTotalCost; // Could be a few cents more or less
  if (residue !== 0) option.items[0].finalCost += residue;
  // ------------------------------------- ------------------------------------- ------------------------------------- //
  // if (option.items.length > 0) console.log('updateOptionProps', option)
  return { ...option };
};
