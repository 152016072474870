import React from 'react';
import Card from 'react-bootstrap/Card';
import { USCurrentStyleDecimal } from '../../utils';

function OptionOutputsTop({ option }) {
  return (
    <Card.Body className='py-1 px-0'>
      {option.monthlyPayment[0] > 0 &&
      option.monthlyPayment[1] > 0 &&
      option.monthlyPayment[0] !== option.monthlyPayment[1] ? (
        <h3 className='text-center display-6 milan-text-navy mb-0'>
          {USCurrentStyleDecimal(option.monthlyPayment[0])}
          <span className='mx-xl-1'>-</span>
          {USCurrentStyleDecimal(option.monthlyPayment[1])}
        </h3>
      ) : (
        <h3 className='text-center display-6 milan-text-navy mb-0'>
          {USCurrentStyleDecimal(option.monthlyPayment[0])}
        </h3>
      )}
      <p className='milan-text-navy text-center mb-1'>
        <small>
          Per month for <strong>{option.financePlan} months</strong>
        </small>
      </p>
    </Card.Body>
  );
}

export default OptionOutputsTop;
