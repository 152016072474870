type LenderProp = {
  name: string;
  rate: {
    min: number; // min interest rate
    max: number; // max interest rate
  };
  downpayment: number; // down payment percentage if required
  minLoanRequired: {
    // min loan required for each term by lender
    [key: number]: number;
  };
  financePlan: number[]; // finance plan available for lender
};

export const createLender = (
  name: string,
  rate: { min: number; max: number },
  downpayment: number,
  minLoanRequired: { [key: number]: number }
): LenderProp => {
  let lender: LenderProp = {
    name,
    rate,
    downpayment,
    financePlan: Object.keys(minLoanRequired).map(Number),
    minLoanRequired: { ...minLoanRequired },
  };

  return lender;
};

export const updateLenderRate = (
  lender: LenderProp,
  rate: { min: number; max: number }
): LenderProp => {
  let lenderCopy = { ...lender };
  lenderCopy.rate = rate;
  return lenderCopy;
};
