import React, { useContext, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { DISCOUNTS } from '../../constants';
import { QuoteContext } from '..';
import TrashCan from '../../assets/Trash-Can.png';
import { updateOptionProps } from '../../utils';

function AreaRow({ option, optionKey, area }) {
  const { options, setOptions, active, clearOption } = useContext(QuoteContext);
  let DISCOUNTS_DROPDOWN =
    area.parentAreaId !== 'bundles'
      ? Object.keys(DISCOUNTS).filter((key) => key !== 'BNDLE')
      : area.id === 'full_body'
      ? Object.keys(DISCOUNTS).filter(
          (key) => !['CSS', 'Event', 'Prev Tx', '60%'].includes(key)
        )
      : area.parentAreaId === 'bundles'
      ? Object.keys(DISCOUNTS).filter((key) => !['CSS'].includes(key))
      : Object.keys(DISCOUNTS);

  // Exclude Prev Tx discount when a Private Sale is selected
  if (active.privateSale !== 'None') {
    DISCOUNTS_DROPDOWN = DISCOUNTS_DROPDOWN.filter(
      (key) => !['Prev Tx', 'CSS'].includes(key)
    );
  }

  const deleteArea = (area, key) => {
    if (option.items.length === 1) {
      // if the last item is deleted
      clearOption(key);
    } else {
      setOptions((prev) => ({
        ...prev,
        [key]: {
          ...options[key],
          items: options[key].items.filter((elem) => elem.id !== area.id),
        },
      }));
    }
  };
  const discountsDropdownHandler = (discount, area, key) => {
    const areaIndex = option.items.findIndex((elem) => elem.id === area.id);
    option.items[areaIndex].discount = discount;
    setOptions((prev) => ({
      ...prev,
      [key]: updateOptionProps(option, active),
    })); // Don't use the option directly
    updateOptionProps();
  };

  useEffect(() => {
    // This will be triggered if the area discount is Prev Tx, Then a Private Sale is selected
    // In that case, default area discount to 50%
    if (active.privateSale !== 'None') {
      if (area.discount === 'Prev Tx' || area.discount === 'CSS') {
        discountsDropdownHandler('50%', area, optionKey);
      }
    }
  }, [active.privateSale]);

  return (
    <div className='container py-1 border-bottom'>
      <div className='row gx-2'>
        <div className='col d-flex align-items-center'>
          <p className='mb-0 milan-fs-7'>{area.name}</p>
        </div>
        <div className='col-auto d-flex align-items-center'>
          <Formik
            initialValues={{ single_discount: area.discount }}
            enableReinitialize
          >
            <Form>
              <Field
                as='select'
                id='single_discount'
                name='single_discount'
                className='form-select py-1'
                style={{
                  width: '125px',
                  backgroundColor: 'inherit',
                  fontSize: '14px',
                }}
                onChange={(e) =>
                  discountsDropdownHandler(e.target.value, area, optionKey)
                }
              >
                {DISCOUNTS_DROPDOWN.map((discountKey, d) => (
                  <option key={d} value={discountKey}>
                    {discountKey === 'CSS' && active.preSale ? (
                      <>
                        {area.parentAreaId.includes('upgrade')
                          ? 'Upgrade'
                          : 'CSS'}
                      </>
                    ) : (
                      discountKey
                    )}
                  </option>
                ))}
              </Field>
            </Form>
          </Formik>
        </div>
        <div className='col-auto print-hide d-flex align-items-center'>
          <span
            className='pointer px-2 py-1'
            onClick={() => deleteArea(area, optionKey)}
          >
            <img
              src={TrashCan}
              alt='trash can icon'
              style={{ maxWidth: '18px' }}
            />
          </span>
        </div>
      </div>
    </div>
  );
}

export default AreaRow;
